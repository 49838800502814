<template name="component-name">
  <div class="input-number-component" :style="boxStyle">
    <span class="in-component-title" :style="titleStyle">{{ title }}</span>
    <a-input-number
      class="in-component-number"
      :placeholder="placeholder"
      :style="inputStyle"
      :disabled="disabled"
      :value="value"
      :min="min"
      :max="max"
      @change="inputNumberChange"
    ></a-input-number>
    <a-tooltip>
      <template slot="title">{{ tooltip }}</template>
      <a-icon
        type="question-circle"
        class="si-component-tooltip"
        v-show="tooltip"
      />
    </a-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    boxStyle: {
      type: String,
      default: ""
    },
    titleStyle: {
      type: String,
      default: ""
    },
    inputStyle: {
      type: String,
      default: ""
    },
    inputName: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: Number
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    }
  },
  methods: {
    inputNumberChange(value) {
      this.$emit("inputNumberChange", value, this.inputName);
    }
  }
};
</script>
<style lang="less">
@import "./../../style/inputNumberComponent.less";
</style>
