<template>
  <div class="accounte-generate-template">
    <FormInputComponent
      label="合同编号"
      name="contract_id"
      decoratorId="contract_id"
      boxStyle="marginBottom: 24px;"
      placeholder="请输入合同编号"
      :getValueFrom="getValueFrom"
      :initialValue="contract_id"
      placement="right"
      tooltip="仅支持英文字母、数字、中划线"
    />
    <InputComponent
      boxStyle="marginBottom: 24px;"
      title="开题单号"
      placeholder="请输入开题单号"
      :value="iproject_id"
      inputName="iproject_id"
      @inputChange="inputChange"
    />
    <InputComponent
      boxStyle="marginBottom: 24px;"
      title="运营邮箱"
      placeholder="请输入运营邮箱"
      :value="email"
      inputName="email"
      @inputChange="inputChange"
      suffix="@personalbio.cn"
      suffixStyle="marginTop: -3px;"
      inputSuffix="si-component-input--suffix"
    />
    <div class="ag-template-data">
      <InputComponent
        inputStyle="width: 360px;"
        title="选择数据"
        :disabled="true"
        placeholder="请选择数据"
        :value="data_dir"
        inputName="data_dir"
        @inputChange="inputChange"
      />
      <div
        :class="
          'agt-data-btn' +
          (contract_id && iproject_id ? ' agt-data-btn--active' : '')
        "
        @click="selectBtn"
      >
        选择
      </div>
    </div>
    <FormInputComponent
      label="有效时间"
      name="expiration"
      suffix="天"
      decoratorId="expiration"
      placeholder="范围1~30天"
      inputStyle="width: 130px;"
      :vselectList="selectList"
      :getValueFrom="getValueFrom"
      :initialValue="expiration"
      suffixStyle="marginTop: -2px;"
    />

    <div
      :class="
        'ag-template-btn' +
        (contract_id && iproject_id && email && data_dir && expiration
          ? ' ag-template-btn--active'
          : '')
      "
      @click="tempAuth"
    >
      {{ btnStatus ? "生成中" : "生成临时访问用户" }}
      <a-icon type="loading" v-show="btnStatus" />
    </div>
    <DirectoryModal
      v-if="visible"
      :visible="visible"
      @modalCb="selectBtn"
      :selectList="selectList"
    />
  </div>
</template>
<script>
import api from "./../../api";
import { contractRe, dateRe } from "./../../utils";
import { requestPostUserName } from "./../../request";
import InputComponent from "./../../components/inputs/InputComponent";
import FormInputComponent from "./../../components/inputs/FormInputComponent";
import InputNumberComponent from "./../../components/inputs/InputNumberComponent";
import DirectoryModal from "./../../components/modals/DirectoryModal";

const emailSuffix = "@personalbio.cn";
export default {
  data() {
    return {
      contract_id: "",
      iproject_id: "",
      email: "psnpod1",
      data_dir: "",
      expiration: "14",
      visible: false,
      emailErr: false,
      selectList: [],
      btnStatus: false,
      backendValue: ""
    };
  },
  mounted() {},
  components: {
    InputComponent,
    DirectoryModal,
    FormInputComponent,
    InputNumberComponent
  },
  methods: {
    // 临时授权
    tempAuth() {
      const {
        contract_id,
        iproject_id,
        email,
        data_dir,
        expiration,
        emailErr
      } = this;
      if (this.btnStatus) return;
      if (!(contract_id && iproject_id && email && data_dir && expiration))
        return;

      this.btnStatus = true;
      requestPostUserName(api.temp_auth, {
        contract_id,
        iproject_id,
        email: email + "@personalbio.cn",
        data_dir,
        expiration: Number(expiration),
        backend: this.backendValue
      })
        .then(({ data }) => {
          if (data.code === 2000 && this.backendValue === "oss") {
            this.$message.success("已生成！", 1, () => {
              this.$router.push("record");
            });
          } else if (data.code === 2000 && this.backendValue === "tos") {
            this.$message.success("已生成！", 2, () => {
              this.$router.push("TOSRecord");
            });
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
          this.btnStatus = false;
        })
        .catch(() => {
          this.btnStatus = false;
        });
    },

    // 监听input框输入
    inputChange(value, type) {
      this[type] = value;
      if (type === "iproject_id") {
        this.selectList[1] = value;
      }
    },

    // 判断输入内容是否正确
    getValueFrom(e) {
      let type = e.target.name;
      let value = e.target.value;
      if (type === "contract_id") {
        if (contractRe.test(value)) {
          this[type] = value;
          this.selectList[0] = value;
          return value;
        }
      } else if (type === "email") {
        this[type] = value;
        return value;
      } else {
        if (dateRe.test(value) || !value) {
          this[type] = value;
          return value;
        }
      }
    },
    // 选择按钮
    selectBtn(type = "show", status = true, path, backendValue) {
      this.backendValue = backendValue;
      const { contract_id, iproject_id } = this;
      if (!(contract_id && iproject_id)) return;
      this.visible = status;
      if (type === "ok") {
        this.data_dir = path;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "./../../style/accountGenerate.less";
</style>
