<template>
  <div class="directory-modal-component">
    <a-modal
      :visible="visible"
      width="650px"
      title="选择目录"
      ok-text="确认"
      cancel-text="取消"
      @cancel="okCancel('cancel', false)"
      @ok="okCancel('ok', false)"
      :dialogClass="
        'dm-component-modal' + (selectPath ? ' dm-component-modal--active' : '')
      "
    >
      <FileBreadcrumb :breadClick="breadClick" :breadList="bread" />
      <div class="dmc-modal-input">
        <div class="directory-dataLabel">
          <div class="directory-labelName">数据源：</div>
          <a-select
            class="directory-select"
            v-model="backendValue"
            @change="dataChange"
          >
            <a-select-option
              v-for="item in backendArr"
              :value="item.value"
              :key="item.value"
            >
              {{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="dmcm-input-wrap">
          <a-input
            placeholder="请选择"
            class="dmcmi-wrap-input"
            @change="inputChange"
            @focus="inputFocus('focus', true)"
            @blur="inputFocus('blur', false)"
            :value="inputValue"
          ></a-input>
          <span class="dmcmiw-input-row--wrap" v-show="!inputValue">
            <a-icon
              type="down"
              @click="inputFocus('row')"
              :class="
                'dmcmiw-input-row ' +
                (selectOpen ? 'dmcmiw-input-row--active' : '')
              "
            />
          </span>
          <span class="dmcmiw-input-row--wrap" v-show="inputValue">
            <a-icon
              type="close-circle"
              @click="inputClear"
              class="dmcmiw-input-close"
            />
          </span>
        </div>
        <a-select
          :open="selectOpen"
          class="dmcm-input-select"
          @change="selectChange"
        >
          <a-select-option
            v-for="(item, index) in selectList"
            :value="item"
            :key="index"
          >
            {{ !index ? "合同编号：" : "开题单号：" }}{{ item }}
          </a-select-option>
        </a-select>
      </div>
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :scroll="{ y: 260 }"
        :customRow="customRow"
        :class="
          'dmc-modal-table' +
          (dataSource.length ? '' : ' dmc-modal-table--error')
        "
      >
        <template slot="action" slot-scope="text, record">
          <span class="dmcd-table-span" @click="clickCell(record)">
            <span style="margin-right: 5px">
              <Iconfont
                :icon="
                  record.type === 'directory'
                    ? fileTypeObj[record.type]
                    : record.type
                    ? record.suffix
                      ? fileTypeObj[record.suffix]
                      : 'unknown-icon'
                    : 'unknown-icon'
                "
              />
            </span>
            <span class="dmcdt-span-txt">{{ record.fname }}</span>
          </span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import api from "./../../api";
import { fileType } from "./../../utils/fileType";
import Iconfont from "./../Iconfont";
import { requestPostUserName } from "./../../request";
import FileBreadcrumb from "./../bars/FileBreadcrumb";
const columns = [
  {
    title: "文件名称",
    dataIndex: "fname",
    key: "fname",
    width: 300,
    scopedSlots: { customRender: "action" }
  },
  {
    title: "大小",
    dataIndex: "fsize",
    key: "fsize"
  },
  {
    title: "日期",
    dataIndex: "ftime",
    key: "ftime"
  }
];

export default {
  props: {
    selectList: {
      type: Array,
      default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns,
      bread: [],
      path: null,
      selectPath: "",
      selectOpen: false,
      inputValue: "",
      dataSource: [],
      dataSourceOrigin: [],
      file_list: [],
      fileTypeObj: fileType,
      backendValue: "tos",
      backendArr: [
        {
          name: "OSS",
          value: "oss"
        },
        {
          name: "TOS",
          value: "tos"
        }
      ]
    };
  },

  mounted() {
    this.datafile("add");
  },
  components: {
    Iconfont,
    FileBreadcrumb
  },
  methods: {
    dataChange() {
      this.datafile();
    },
    // 选择数据文件
    datafile(type, record) {
      requestPostUserName(api.datafile, {
        fpath: this.path,
        backend: this.backendValue
      })
        .then(({ data }) => {
          let dataSource = [];
          if (data.code === 2000) {
            data.file_list.forEach((item) => {
              dataSource.push({
                key: item.path,
                ...item
              });
            });
            this.dataSource = dataSource;
            this.dataSourceOrigin = JSON.parse(JSON.stringify(dataSource));
            if (type === "add") {
              if (record) {
                this.bread.push({
                  name: record.fname,
                  path: record.path
                });
              } else {
                this.bread.push({
                  name: "home",
                  path: null
                });
              }
            } else {
              if (record.name === "home") {
                this.bread = [
                  {
                    name: "home",
                    path: null
                  }
                ];
              } else {
                let index = this.bread.findIndex(
                  (item) => item.name === record.name
                );
                this.bread = this.bread.slice(0, index + 1);
              }
            }
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },

    // 选中当前行
    customRow(record, index) {
      return {
        props: {
          name: index
        },
        on: {
          click: (e) => {
            // if (record.type === "directory") return;
            if (this.bread.length < 3) return;
            $(`.ant-table-row:eq(${index})`)
              .siblings()
              .removeClass("dmcd-table-tr");
            if ($(`.ant-table-row:eq(${index})`).hasClass("dmcd-table-tr")) {
              this.selectPath = "";
              $(`.ant-table-row:eq(${index})`).removeClass("dmcd-table-tr");
            } else {
              this.selectPath = record.path;
              $(`.ant-table-row:eq(${index})`).addClass("dmcd-table-tr");
            }
          }
        }
      };
    },
    // 点击单元格
    clickCell(record) {
      if (record.type !== "directory") return;
      this.path = record.path;
      this.datafile("add", record);
    },
    // 弹框确认取消事件
    okCancel(type, status) {
      let { selectPath } = this;
      if (type === "ok" && !selectPath) return;
      this.$emit("modalCb", type, status, selectPath, this.backendValue);
    },
    // 输入框
    inputChange(e) {
      let value = e.target.value;
      this.inputValue = value;
      this.selectOpen = value ? false : true;
      if (!value) {
        this.dataSource = JSON.parse(JSON.stringify(this.dataSourceOrigin));
      } else {
        this.dataSource = this.dataSource.filter(
          (item) => item.fname.indexOf(value) !== -1
        );
      }
    },
    // 输入框聚焦
    inputFocus(type, status) {
      if (type === "row") {
        this.selectOpen = this.selectOpen ? false : true;
      } else {
        this.selectOpen = status;
      }
    },
    // 清楚输入框内容
    inputClear() {
      this.inputValue = "";
      this.dataSource = JSON.parse(JSON.stringify(this.dataSourceOrigin));
    },
    // 选择框
    selectChange(value) {
      this.selectOpen = false;
      this.inputValue = value;
      this.dataSource = this.dataSource.filter(
        (item) => item.fname.indexOf(value) !== -1
      );
    },
    // 面包屑
    breadClick(params) {
      if (params.name === this.bread[this.bread.length - 1].name) return;
      this.selectPath = "";
      this.path = params.path;
      this.datafile("reduce", params);
    }
  }
};
</script>
<style lang="less">
@import "./../../style/directoryModal.less";
// @import "./../../style/fileType.less";
</style>
