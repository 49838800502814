<template name="component-name">
  <div class="file-breadcrumb-component">
    <a-breadcrumb class="fb-component-breadcrumb">
      <template v-for="(item, index) in breadcrumb">
        <a-breadcrumb-item
          class="fbc-breadcrumb-item"
          :key="item.name"
          v-if="!item.children"
        >
          <span
            :class="
              breadcrumb.length === 1 || breadcrumb.length - 1 === index
                ? ''
                : 'fbcb-item-txt'
            "
            @click="breadClick(item)"
            >{{ item.name }}</span
          >
        </a-breadcrumb-item>
        <a-breadcrumb-item class="fbc-breadcrumb-item" :key="item.name" v-else>
          <span class="fbcb-item-txt--color">{{ item.name }}</span>
          <a-menu slot="overlay">
            <a-menu-item v-for="val in item.children" :key="val.name">
              <span @click="breadClick(val)">{{ val.name }}</span>
            </a-menu-item>
          </a-menu>
        </a-breadcrumb-item>
      </template>
    </a-breadcrumb>
    <!-- 隐藏算出面包屑的宽度 -->
    <a-breadcrumb class="fb-component-breadcrumb--opacity">
      <template v-for="item in breadList">
        <a-breadcrumb-item class="fbc-breadcrumb-item--hide" :key="item.name">
          {{ item.name }}
        </a-breadcrumb-item>
      </template>
    </a-breadcrumb>
  </div>
</template>
<script>
export default {
  props: {
    breadClick: {
      type: Function,
      default: function () {}
    },
    breadList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      breadcrumb: []
      // breadcrumb: [
      //   {
      //     name: "home",
      //     path: "ddd"
      //   },
      //   {
      //     name: "homedadfasd",
      //     path: "ddd"
      //   },
      //   {
      //     name: "asdfewew",
      //     path: "ddd"
      //   },
      //   {
      //     name: "hasdfadfwwafome",
      //     path: "ddd"
      //   },
      //   {
      //     name: "hdfadfwwafe",
      //     path: "ddd"
      //   },
      //   {
      //     name: "eeeeeee",
      //     path: "ddd"
      //   },
      //   {
      //     name: "rrrrrrrrrr",
      //     path: "ddd"
      //   },
      //   {
      //     name: "hhhhhhhhhh",
      //     path: "ddd"
      //   }
      // ]
    };
  },
  watch: {
    breadList: {
      handler(newVal, oldVal) {
        if (newVal.length <= 2) {
          this.breadcrumb = newVal;
        }
        this.$nextTick(() => {
          if (newVal.length > 2) {
            this.breadcrumbWidth();
          }
        });
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    breadcrumbWidth() {
      let cIndex = 0;
      this.breadcrumb = JSON.parse(JSON.stringify(this.breadList));
      let parentWidth = $(".file-breadcrumb-component").outerWidth();
      Array.prototype.forEach.call(
        $(".fbc-breadcrumb-item--hide"),
        (item, index) => {
          this.breadcrumb[index].width = $(item).outerWidth();
        }
      );
      let width = this.breadcrumb[this.breadcrumb.length - 1].width;
      this.breadcrumb.forEach((item, index) => {
        if (index < this.breadcrumb.length - 1) {
          if (width < parentWidth) {
            cIndex = index;
            width += item.width;
          }
        }
      });

      if (cIndex && width >= parentWidth) {
        this.breadcrumb[cIndex - 1].children = this.breadcrumb.splice(
          cIndex,
          this.breadcrumb.length - 1 - cIndex
        );
      }
    }
  }
};
</script>
<style lang="less">
@import "./../../style/fileBreadcrumb.less";
</style>
